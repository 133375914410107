.tsc_clear { clear:both; padding:0; margin:0; width:100%; font-size:0px; line-height:0px;}

.tsc_tabs_type_1 dd.current { display:block;}
.tsc_tabs_type_1 { width:100%; height:468px; box-shadow:0 0 3px #c3c3c3; border-radius:10px; background-color:#f8f8f8; overflow:hidden; position:relative; margin:0; }
.tsc_tabs_type_1 dt { width:22%; border-bottom:1px solid #ededed; font-size:12px; font-weight:700; cursor:pointer; padding:14px 0 14px 3%; }
.tsc_tabs_type_1 dt img { display:inline-block; margin-right:15px; position:relative; top:5px; }
.tsc_tabs_type_1 dd { margin-left:25%; background-color:#FFF; height:100%; box-shadow:inherit; overflow:auto; position:absolute; top:0; right:0; display:none; z-index:1000; padding:4%; }
.tsc_tabs_type_1 dt:first-child { margin-top:0; }
.tsc_tabs_type_1 dd .column_group p:first-child { margin-top:0; }
.tsc_tabs_type_1_arrow { position:absolute; top:0; left:23%; z-index:1001; width:18px; height:36px; }
.tsc_tabs_type_1 dt.current,
.tsc_tabs_type_2 dt.current { color:#01C3FD; background-color:#fff; }



.tsc_tabs_type_2 dd.current { display:block; }
.tsc_tabs_type_2 { position:relative; margin:0; font-size:12px; height:330px; }
.tsc_tabs_type_2 dt { font-size:12px; float:left; margin-left:0; margin-right:5px; cursor:pointer; font-weight:bold; box-shadow:0 0 3px #c3c3c3; border-radius:10px 10px 0 0; height:40px; line-height:40px; padding:0 16px; background-color:#f8f8f8; }
.tsc_tabs_type_2 dt.current { background-color:#fff; }
.tsc_tabs_type_2 dd { display:none; position:absolute; margin:0; background:#FFF; top:40px; left:0; padding:30px; border-radius:0 10px 10px 10px; box-shadow:0 0 3px #c3c3c3; height:230px; }
.tsc_tabs_type_2_arrow { position:absolute; top:38px; z-index:1001; width:28px; height:15px; }




    #tsc_tabs_type_3_container {
      width: 100%;
    }
    

    #tsc_tabs_type_3 {
      overflow: hidden;
      width: 100%;
      margin: 0;
      padding: 0;
      list-style: none;
    }

    #tsc_tabs_type_3 li {
      float: left;
      margin: 0 -15px 0 0;
    }

    #tsc_tabs_type_3 a {
      float: left;
	  position: relative;
	  padding: 0 40px;
      height: 0; 
	  line-height: 30px;
      text-transform: uppercase;
      text-decoration: none;
      color: #fff;	  
      border-right: 30px solid transparent;
      border-bottom: 30px solid #3D3D3D;
      border-bottom-color: #777\9;
      opacity: .3;
      filter: alpha(opacity=30);	  
    }

    #tsc_tabs_type_3 a:hover,
    #tsc_tabs_type_3 a:focus {
      border-bottom-color: #2ac7e1;
      opacity: 1;
      filter: alpha(opacity=100);
    }

    #tsc_tabs_type_3 a:focus {
      outline: 0;
    }

    #tsc_tabs_type_3 #current {
      z-index: 3;
      border-bottom-color: #3d3d3d;
      opacity: 1;
      filter: alpha(opacity=100);	  
    }

    /* ----------- */
    #tsc_tabs_type_3_content {
        background: #fff;
        border-top: 2px solid #3d3d3d;
        border-bottom: 1px solid #ccc;
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
        padding: 2em;
        /*height: 220px;*/
    }

    #tsc_tabs_type_3_content h2,
  	#tsc_tabs_type_3_content h3,
  	#tsc_tabs_type_3_content p {
        margin: 0 0 15px 0;
    }  










    #tsc_tabs_type_4_container {
      width: 100%;

    }




#tsc_tabs_type_4
{
  overflow: auto;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;

}

#tsc_tabs_type_4 li
{
    margin: 0;
    padding: 0;
    float: left;

}

#tsc_tabs_type_4 a
{
    -moz-box-shadow: -4px 0 0 rgba(0, 0, 0, .2);
    -webkit-box-shadow: -4px 0 0 rgba(0, 0, 0, .2);
    box-shadow: -4px 0 0 rgba(0, 0, 0, .2);
    background: #ad1c1c;
    background:    -moz-linear-gradient(220deg, transparent 10px, #ad1c1c 10px);
    background:    -webkit-linear-gradient(220deg, transparent 10px, #ad1c1c 10px);     
    background:     -ms-linear-gradient(220deg, transparent 10px, #ad1c1c 10px); 
    background:      -o-linear-gradient(220deg, transparent 10px, #ad1c1c 10px); 
    background:         linear-gradient(220deg, transparent 10px, #ad1c1c 10px);
    text-shadow: 0 1px 0 rgba(0,0,0,.5);
    color: #fff;
    float: left;
    font: bold 12px/35px 'Lucida sans', Arial, Helvetica;
    height: 35px;
    padding: 0 30px;
    text-decoration: none;
    
}

#tsc_tabs_type_4 a:hover
{
    background: #c93434;
    background:    -moz-linear-gradient(220deg, transparent 10px, #c93434 10px);
    background:    -webkit-linear-gradient(220deg, transparent 10px, #c93434 10px);     
    background:     -ms-linear-gradient(220deg, transparent 10px, #c93434 10px); 
    background:      -o-linear-gradient(220deg, transparent 10px, #c93434 10px); 
    background:         linear-gradient(220deg, transparent 10px, #c93434 10px);     

}

#tsc_tabs_type_4 a:focus
{
    outline: 0;

}

#tsc_tabs_type_4 #current a
{
    background: #ccc;
    background:    -moz-linear-gradient(220deg, transparent 10px, #fff 10px);
    background:    -webkit-linear-gradient(220deg, transparent 10px, #fff 10px);     
    background:     -ms-linear-gradient(220deg, transparent 10px, #fff 10px); 
    background:      -o-linear-gradient(220deg, transparent 10px, #fff 10px); 
    background:         linear-gradient(220deg, transparent 10px, #fff 10px);
    text-shadow: none;    
    color: #333;
    background: #f2f2f2;
    
}

#tsc_tabs_type_4_content
{
    background-color: #fff;
border-top: 1px solid #c93434;
    padding: 16px;

border-bottom: 1px solid #c93434;
border-left: 1px solid #c93434;
border-right: 1px solid #c93434;
}














.tsc_flat_tab_main_container {width: 100%px;}

ul.flat_tabs {
	margin: 0;
	padding: 0;
	float: left;
	list-style: none;
	height: 32px;
	border-bottom: 1px solid #ccc;
	border-left: 1px solid #ccc;
	width: 100%;
}
ul.flat_tabs li {
	float: left;
	margin: 0;
	padding: 0;
	height: 31px;
	line-height: 31px;
	border: 1px solid #ccc;
	border-left: none;
	margin-bottom: -1px;
	background: #eee;
	overflow: hidden;
	position: relative;
}
ul.flat_tabs li a {
	text-decoration: none;
	color: #000;
	display: block;
	font-size: 1.2em;
	padding: 0 20px;
	border: 1px solid #fff;
	outline: none;
}
ul.flat_tabs li a:hover {
	background: #ccc;
}	
html ul.flat_tabs li.active, html ul.flat_tabs li.active a:hover  {
	background: #fff;
	border-bottom: 1px solid #fff;
}
.tsc_flat_tab_subcontainer {
	border: 1px solid #ccc;
	border-top: none;
	clear: both;
	float: left; 
	width: 100%;
	background: #fff;
	-moz-border-radius-bottomright: 5px;
	-khtml-border-radius-bottomright: 5px;
	-webkit-border-bottom-right-radius: 5px;
	-moz-border-radius-bottomleft: 5px;
	-khtml-border-radius-bottomleft: 5px;
	-webkit-border-bottom-left-radius: 5px;
}
.tsc_flat_tab_content {
	padding: 20px;
	font-size: 1.2em;
}
.tsc_flat_tab_content h2 {
	font-weight: normal;
	padding-bottom: 10px;
	border-bottom: 1px dashed #ddd;
	font-size: 1.8em;
}
.tsc_flat_tab_content h3 a{
	color: #254588;
}
.tsc_flat_tab_content img {
	float: left;
	margin: 0 20px 20px 0;
	border: 1px solid #ddd;
	padding: 5px;
}










.tsc_gradient-tabs {
    position: relative;
	width: 100%;
}

.tsc_gradient-tabs input {
	position: absolute;
	z-index: 1000;
	width: 120px;
	height: 40px;
	left: 0px;
	top: 0px;
	opacity: 0;
    -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
	cursor: pointer;
}
.tsc_gradient-tabs input#grad-tab-2{
	left: 120px;
}
.tsc_gradient-tabs input#grad-tab-3{
	left: 240px;
}
.tsc_gradient-tabs input#grad-tab-4{
	left: 360px;
}

.tsc_gradient-tabs label {
	background: #ddd;
	background: -moz-linear-gradient(top, #eee 0%, #ddd 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#eee), color-stop(100%,#ddd));
	background: -webkit-linear-gradient(top, #eee 0%,#ddd 100%);
	background: -o-linear-gradient(top, #eee 0%,#ddd 100%);
	background: -ms-linear-gradient(top, #eee 0%,#ddd 100%);
	background: linear-gradient(top, #eee 0%,#ddd 100%);

	
	font-size: 15px;
	line-height: 40px;
	height: 40px;
	position: relative;
	padding: 0 20px;
    float: left;
	display: block;
	width: 80px;
	color: #333;
	letter-spacing: 1px;
	text-transform: uppercase;

	text-align: center;
	text-shadow: 1px 1px 1px rgba(255,255,255,0.3);
    border-radius: 3px 3px 0 0;
    box-shadow: 2px 0 2px rgba(0,0,0,0.1), -2px 0 2px rgba(0,0,0,0.1);
}

.tsc_gradient-tabs label:after {
    content: '';
	background: #fff;
	position: absolute;
	bottom: -2px;
	left: 0;
	width: 100%;
	height: 2px;
	display: block;
}

.tsc_gradient-tabs input:hover + label {
	background: #fff;

}

.tsc_gradient-tabs label:first-of-type {
    z-index: 4;
    box-shadow: 2px 0 2px rgba(0,0,0,0.1);
}

.grad-tab-label-2 {
    z-index: 3;
}

.grad-tab-label-3 {
    z-index: 2;
}

.grad-tab-label-4 {
    z-index: 1;
}

.tsc_gradient-tabs input:checked + label {
    background: #f2f2f2;
	z-index: 6;
/*	border-left: 1px solid #f2f2f2; */
    -webkit-animation: page 0.2s linear;
    -moz-animation: page 0.2s linear;
    -ms-animation: page 0.2s linear;
    -o-animation: page 0.2s linear;
    animation: page 0.2s linear;
}

.clear-shadow {
	clear: both;
}

.gradtab-content {
    background: #fff;
	position: relative;
    width: 100%;
	height: 240px;
	z-index: 5;
	overflow: hidden;
    
     box-shadow: 1px 2px 7px rgba(0,0,0,0.2);
    border-radius: 0 3px 3px 3px;
}

.gradtab-content div {
    position: absolute;
	top: 0;
	padding: 10px 40px;
	z-index: 1;
    opacity: 0;
    -webkit-transition: all linear 0.3s;
    -moz-transition: all linear 0.3s;
    -o-transition: all linear 0.3s;
    -ms-transition: all linear 0.3s;
    transition: all linear 0.3s;
}

.gradtab-content-1, .gradtab-content-3 {
	-webkit-transform: translateX(-250px);
	-moz-transform: translateX(-250px);
	-o-transform: translateX(-250px);
	-ms-transform: translateX(-250px);
	transform: translateX(-250px);
}

.gradtab-content-2, .gradtab-content-4 {
    -webkit-transform: translateX(250px);
	-moz-transform: translateX(250px);
	-o-transform: translateX(250px);
	-ms-transform: translateX(250px);
	transform: translateX(250px);
}

.tsc_gradient-tabs input.grad-tab-selector-1:checked ~ .gradtab-content .gradtab-content-1,
.tsc_gradient-tabs input.grad-tab-selector-2:checked ~ .gradtab-content .gradtab-content-2,
.tsc_gradient-tabs input.grad-tab-selector-3:checked ~ .gradtab-content .gradtab-content-3,
.tsc_gradient-tabs input.grad-tab-selector-4:checked ~ .gradtab-content .gradtab-content-4 {
    -webkit-transform: translateX(0px);
	-moz-transform: translateX(0px);
	-o-transform: translateX(0px);
	-ms-transform: translateX(0px);
	transform: translateX(0px);
	z-index: 100;
    -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
    -webkit-transition: all ease-out 0.2s 0.1s;
    -moz-transition: all ease-out 0.2s 0.1s;
    -o-transition: all ease-out 0.2s 0.1s;
    -ms-transition: all ease-out 0.2s 0.1s;
    transition: all ease-out 0.2s 0.1s;
}

.gradtab-content div h2,
.gradtab-content div h3{
	color: #dd8d2b;
}
.gradtab-content div p {
	font-size: 14px;
	line-height: 22px;
	text-align: left;
	margin: 0;
	color: #777;
	padding-left: 15px;
	border-left: 8px solid rgba(204,204,204, 0.2);
}









.tsc_gradient-vtabs {
    position: relative;
	
	width: 100%;
}

.tsc_gradient-vtabs input {
	position: absolute;
	z-index: 1000;
	width: 120px;
	height: 40px;
	left: 0px;
	top: 0px;
	opacity: 0;
    -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
	cursor: pointer;
}
.tsc_gradient-vtabs input#vgrad-tab-2{
	top: 40px;
}
.tsc_gradient-vtabs input#vgrad-tab-3{
	top: 80px;
}
.tsc_gradient-vtabs input#vgrad-tab-4{
	top: 120px;
}

.tsc_gradient-vtabs label {
	background: #ddd;
	background: -moz-linear-gradient(top, #eee 0%, #ddd 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#eee), color-stop(100%,#ddd));
	background: -webkit-linear-gradient(top, #eee 0%,#ddd 100%);
	background: -o-linear-gradient(top, #eee 0%,#ddd 100%);
	background: -ms-linear-gradient(top, #eee 0%,#ddd 100%);
	background: linear-gradient(top, #eee 0%,#ddd 100%);
	font-size: 13px;
	line-height: 40px;
	height: 40px;
	position: relative;
	padding: 0 20px;
	display: block;
	width: 100px;
	color: #333;
	letter-spacing: 1px;
	text-transform: uppercase;

	text-align: right;
	float: left;
	clear: both;
	text-shadow: 1px 1px 1px rgba(255,255,255,0.3);
    border-radius: 3px 0 0 3px;
    box-shadow: 0px 2px 2px rgba(0,0,0,0.1);
}

.tsc_gradient-vtabs label:after {
    content: '';
	background: #ff2f2ff;
	position: absolute;
	right: -2px;
	top: 0;
	width: 2px;
	height: 100%;
}

.tsc_gradient-vtabs input:hover + label {
	background: #ffffff;
}

.tsc_gradient-vtabs label:first-of-type {
    z-index: 4;
}

.vgrad-tab-label-2 {
    z-index: 3;
}

.vgrad-tabtab-label-3 {
    z-index: 2;
}

.vgrad-tabtab-label-4 {
    z-index: 1;
}

.tsc_gradient-vtabs input:checked + label {
    background: #f2f2f2;
	z-index: 6;
}

.clear-shadow {
	clear: both;
}

.vgradtab-content {
    background: #fff;
	position: relative;
    width: auto;
	margin: -175px 0 0 120px;
	height: 250px;
	z-index: 5;
	overflow: hidden;
box-shadow: 1px 2px 7px rgba(0,0,0,0.2);
    border-radius: 3px;
}

.vgradtab-content div {
    position: absolute;
	top: 0;
	padding: 10px 40px;
	z-index: 1;
    opacity: 0;
    -webkit-transition: all linear 0.5s;
    -moz-transition: all linear 0.5s;
    -o-transition: all linear 0.5s;
    -ms-transition: all linear 0.5s;
    transition: all linear 0.5s;
}

.vgradtab-content div{
	-webkit-transform: translateY(-450px);
	-moz-transform: translateY(-450px);
	-o-transform: translateY(-450px);
	-ms-transform: translateY(-450px);
	transform: translateY(-450px);
}

.tsc_gradient-vtabs input.vgrad-tab-selector-1:checked ~ .vgradtab-content .vgradtab-content-1,
.tsc_gradient-vtabs input.vgrad-tab-selector-2:checked ~ .vgradtab-content .vgradtab-content-2,
.tsc_gradient-vtabs input.vgrad-tab-selector-3:checked ~ .vgradtab-content .vgradtab-content-3,
.tsc_gradient-vtabs input.vgrad-tab-selector-4:checked ~ .vgradtab-content .vgradtab-content-4 {
    -webkit-transform: translateY(0px);
	-moz-transform: translateY(0px);
	-o-transform: translateY(0px);
	-ms-transform: translateY(0px);
	transform: translateY(0px);
    z-index: 100;
    -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
    -webkit-transition: all ease-out 0.3s 0.3s;
    -moz-transition: all ease-out 0.3s 0.3s;
    -o-transition: all ease-out 0.3s 0.3s;
    -ms-transition: all ease-out 0.3s 0.3s;
    transition: all ease-out 0.3s 0.3s;
}

.vgradtab-content div h2,
.vgradtab-content div h3{
	color: #dd8d2b;
}
.vgradtab-content div p {
	font-size: 14px;
	line-height: 22px;
	text-align: left;
	margin: 0;
	color: #777;
	padding-left: 15px;
	border-left: 8px solid rgba(204,204,204, 0.2);
}
